/* Fonts */
@import url("https://fonts.cdnfonts.com/css/switzer");

@font-face {
  font-family: "Aire Bold";
  src: url("./Assets/fonts/Aire\ Bold\ Pro.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: #f8f3ed;
  margin: 0;
  font-family: "Switzer", sans-serif;
}

a {
  text-decoration: none;
}

li {
  text-decoration: none;
  list-style: none;
}

.App {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  position: relative;
}

.splash-screen {
  background-color: #1e1e1e;
  width: 100%;
  height: 100vh;
  position: relative;
}

.welcome-text-container {
  margin-left: 10%;
  padding-top: 10%;
  color: #f8f3ed;
  font-weight: 500;
  font-size: 72px;
  letter-spacing: -2px;
  line-height: 75px;
  opacity: 0; /* Set initial opacity to 0 */
  transition: opacity 2s ease-in-out; /* Define the transition animation */
}

.welcome-mobile-text-container {
  margin-left: 15px;
  padding-top: 50px;
  color: #f8f3ed;
  font-weight: 500;
  font-size: 58px;
  letter-spacing: -2px;
  line-height: 75px;
  opacity: 0; /* Set initial opacity to 0 */
  transition: opacity 2s ease-in-out; /* Define the transition animation */
  display: none;
}

.fade-in {
  opacity: 1;
  transition: opacity 2s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1e1e1e;
  opacity: 1;
  z-index: 9999;
}

.overlay.fade-out {
  animation: fadeOut 3s ease forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}

.welcome-text-container span {
  font-weight: 200;
  font-style: italic;
}

.welcome-text-container p {
  margin: 0;
}
.welcome-mobile-text-container span {
  font-weight: 200;
  font-style: italic;
}

.welcome-mobile-text-container p {
  margin: 0;
}

.splash-btn-container {
  position: absolute;
  bottom: 200px;
  right: 300px;
  cursor: pointer;
}

.splash-btn-1 {
  position: absolute;
  width: 140px;
}
.splash-btn-2 {
  position: absolute;
  width: 140px;
  opacity: 0;
}

.splash-btn-container:hover .splash-btn-1 {
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.splash-btn-container:hover .splash-btn-2 {
  opacity: 1;
  transition: 0.5s ease-in-out;
}

.splash-btn-container:active::after .splash-btn-2 {
  opacity: 1;
}

.landing-container {
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
}

hr {
  margin: 0;
  border: none;
  height: 1px;
  background-color: #1c1712bf;
}

.navContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.nav {
  text-align: right;
  line-height: 28px;
}

.nav a {
  color: #82b29b;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #909998; /* Adjust the opacity as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 11;
  opacity: 0; /* Initially set the opacity to 0 for fading effect */
  pointer-events: none; /* Disable pointer events to prevent interaction before animation */
  animation: fade-in 0.5s ease-in-out forwards; /* Apply fade-in animation */
  overflow: hidden;
}

.mobile-nav-header {
  width: 100%;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  justify-content: space-between;
}

.mobile-nav-logo {
  margin: 16px;
}

.close-button {
  color: white;
  font-size: 22px;
  padding: 16px;
  cursor: pointer;
}

.fullscreen-menu.show {
  pointer-events: auto; /* Enable pointer events to allow interaction */
}

.fullscreen-menu ul {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  line-height: 45px;
}

.fullscreen-menu li {
  padding: 10px 0;
}

.fullscreen-menu a {
  color: #f8f3ed;
  font-size: 72px;
  font-weight: 400;
  letter-spacing: -2px;
}

.fullscreen-menu a:hover {
  color: #f8f3edaa;
}

.content-div {
  padding: 50px 0 0 60px;
  position: relative;
}

.profile-photo-div {
  width: 400px;
  position: absolute;
  top: 240px;
  right: 250px;
}

.profile-img {
  width: 100%;
}

.text2 {
  line-height: 25px;
  color: #0d0c06d5;
  position: relative;
}

.view-portfolio {
  position: absolute;
  right: -50px;
  z-index: 10;
}

.text2 span {
  font-weight: 900;
  font-style: italic;
}

.image-container {
  width: 100%;
  min-height: 110vh;
  overflow: hidden;
  object-fit: cover;
  position: relative;
  background-color: #1c1712;
}

.landing-img {
  overflow: hidden;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  min-height: 100%;
  opacity: 70%;
}

.text-overlay {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.text-overlay p {
  font-family: "Switzer", sans-serif;
  font-size: 96px;
  letter-spacing: -5px;
  line-height: 85px;
  text-align: center;
  color: #92c6ad;
  margin: 40px 0;
  font-weight: 400;
}

.profile-hero-text {
  font-size: 14px;
}

.desktop-scroll-btn {
  display: block;
  width: 150px;
  margin: 0 auto;
}

.view-skills-btn {
  background-color: #f8f3ed;
  padding: 12px 28px;
  border: 1px solid #232323;
  border-radius: 35px;
  font-weight: 500;
  cursor: pointer;
  display: block;
  margin: 25px auto;
}

.view-skills-btn:hover {
  background-color: #232323;
  color: #f8f3ed;
}

.landing-page-heading {
  position: relative;
  font-style: italic;
  font-size: 42px;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: -2px;
  z-index: 10;
  color: #1e1e1e;
}
.landing-page-heading span {
  font-size: 64px;
  font-weight: 500;
  font-style: normal;
}

.about-mobile-slider {
  display: none;
}

.about-mobile-slider2 {
  display: none;
}

.mobile-scroll-btn {
  display: none;
}

.slide1-container {
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
  width: 85%;
  margin: 0 auto;
}

.about-title {
  position: absolute;
  color: #92c6ad;
  top: 2%;
  right: -25px;
  font-size: 72px;
  font-weight: 500;
  letter-spacing: -3px;
}

.about-title2 {
  position: absolute;
  color: #92c6ad;
  top: 30px;
  left: -50px;
  font-family: "Switzer", sans-serif;
  font-size: 108px;
  font-weight: 400;
  line-height: 85px;
  letter-spacing: -5px;
  margin-top: 0;
}

.about-text-container {
  width: 85%;
}

.about-text {
  width: 100%;
}

.about-text1 {
  font-size: 38px;
  line-height: 45px;
  letter-spacing: -1.5px;
  margin: 0;
}

.about-text2 {
  margin-top: 35px;
  width: 50%;
  line-height: 25px;
}

.about-image-div {
  position: relative;
  margin-top: 80px;
}

.mobile-menu-btn {
  cursor: pointer;
}

.about-image {
  width: 300px;
}

.arrow-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.about-down-arrow {
  margin: 25px 45px 0 0;

  /* floating animation */
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.about-down-arrow-desktop {
  margin: 25px 85px 0 0;

  /* floating animation */
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.next-btn {
  position: absolute;
  bottom: -90px;
  left: 15px;
  cursor: pointer;
}

.overlay-next-btn {
  position: absolute;
  bottom: -90px;
  left: 15px;
  cursor: pointer;
  opacity: 0;
}

.next-btn-container:hover .next-btn {
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.next-btn-container:hover .overlay-next-btn {
  opacity: 1;
  transition: 0.5s ease-in-out;
}

.slide2-container {
  height: 100vh;
  background-color: #1e1e1e;
  position: relative;
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
}

.img-container-slide2 {
  position: relative;
  height: 400px;
  margin-top: 130px;
}

.about-img-2 {
  width: 280px;
  position: absolute;
  right: 0;
  bottom: 70px;
}

.prev-btn {
  position: absolute;
  bottom: 45px;
  right: 8%;
  cursor: pointer;
  width: 150px;
}

.prev-btn-container:hover .prev-btn {
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.prev-btn-container:hover .overlay-prev-btn {
  opacity: 1;
  transition: 0.5s ease-in-out;
  width: 150px;
}

.overlay-prev-btn {
  position: absolute;
  bottom: 45px;
  right: 8%;
  opacity: 0;
}

.about-heading {
  position: absolute;
  top: -150px;
  left: 12%;
  font-size: 56px;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 55px;
  color: #f8f3ed;
  z-index: 1;
}
.img-container-slide2 span {
  font-weight: 200;
  font-style: italic;
}

.about-mobile-slider2 span {
  font-weight: 200;
  font-style: italic;
}

.text-container-slide2 {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  color: #f8f3ed;
  line-height: 25px;
  font-weight: 200;
}

.text-container-slide2 h1 {
  font-weight: 400;
}

.text-container-slide2 h3 {
  font-size: 22px;
  font-weight: 300;
}

.text-container-slide2 a {
  color: #f8f3ed;
  text-decoration: underline;
}

.about-footer-section {
  width: 85%;
  margin: 120px auto 80px auto;
  position: relative;
}

.about-footer-section h2 {
  text-align: right;
  font-family: "Switzer", sans-serif;
  color: #92c6ad;
  font-size: 96px;
  font-weight: 400;
  margin: 0;
  letter-spacing: -4px;
  line-height: 80px;
}

.about-footer-mobile {
  display: none;
}

.about-footer-section p {
  margin: 40px 0;
  width: 35%;
  color: #1f1f1fd5;
  line-height: 25px;
}

.about-footer-section a {
  color: #6ca68a;
  font-size: 18px;
}

.aboutFooterImg {
  position: absolute;
  right: 0;
  z-index: -1;
}

.back-home-btn {
  position: absolute;
  right: 200px;
  display: flex;
  align-items: center;
}
.back-home-btn h3 {
  padding-right: 15px;
  font-weight: 400;
  color: #1c1712;
}

.skills-container {
  padding: 80px 0 80px 0;
}

.skills-container h3 {
  font-size: 22px;
  font-weight: 300;
  font-style: italic;
  letter-spacing: -1px;
  color: #6ca68a;
  padding: 20px 0;
  margin: 0;
}

.skills-container span {
  padding-left: 10px;
}

.accordion-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 75%;
  margin: 0 auto;
}

.answer1 {
  min-height: fit-content;
  font-size: 22px;
  font-weight: 500;
  line-height: 0px;
  letter-spacing: -1px;
  font-style: italic;
  padding-left: 30%;
  color: #1e1e1e;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out; /* Add the transition property */
}
.answer2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 0px;
  letter-spacing: -1px;
  font-style: italic;
  padding-left: 60%;
  color: #1e1e1e;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out; /* Add the transition property */
}
.answer3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 0px;
  letter-spacing: -1px;
  font-style: italic;
  padding-left: 40%;
  color: #1e1e1e;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out; /* Add the transition property */
}

.open {
  max-height: 350px; /* Set the max-height to the desired value */
}

.closed {
  max-height: 0;
}

.drop-arrow {
  transition: transform 0.5s ease; /* Add transition for smooth rotation */
}

.arrow-open {
  transform: rotate(90deg); /* Rotate the icon 90 degrees when open */
}

.arrow-closed {
  transform: rotate(0deg); /* Reset the rotation when closed */
}

.portfolio-heading {
  font-size: 64px;
  font-weight: 500;
  color: #92c6ad;
  text-align: center;
  letter-spacing: -1.5px;
}

.portfolio-cards-container {
  width: 85%;
  margin: 65px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(6, 1fr);
}

.influenced {
  max-width: 300px;
  grid-row: 2/6;
  margin: 0 auto;
  overflow: hidden;
}

.card-number {
  font-size: 12px;
  font-weight: 200;
  margin: 0;
}

.influenced h1 {
  font-weight: 500;
  letter-spacing: -1.5px;
  margin: 5px 0 10px 0;
}

.influenced img {
  width: 85%;
}

.minimaliste {
  max-width: 300px;
  grid-row: 1/5;
  margin: 0 auto;
  overflow: hidden;
}

.minimaliste h1 {
  font-weight: 500;
  letter-spacing: -1.5px;
  margin: 5px 0 10px 0;
}

.minimaliste img {
  width: 85%;
}

.dev-portfolio {
  max-width: 300px;
  grid-row: 3/6;
  margin: 0 auto;
  overflow: hidden;
}

.dev-portfolio h1 {
  font-weight: 500;
  letter-spacing: -1.5px;
  margin: 5px 0 10px 0;
}

.dev-portfolio img {
  width: 100%;
}

.card-par {
  font-weight: 200;
  margin: 0;
}

.view-case-study-btn {
  background-color: rgba(255, 255, 255, 0);
  padding: 8px 20px;
  border: 1px solid #232323;
  border-radius: 35px;
  font-weight: 500;
  margin: 15px 0;
  cursor: pointer;
}

.view-case-study-btn:hover {
  background-color: #b9cfcf;
}

.contact-container {
  height: 70vh;
  position: relative;
}

.contact-block1 {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
}

.text-block1 {
  width: 450px;
  font-size: 18px;
  color: #1e1e1e;
}

.get-in-touch {
  font-weight: 600;
  letter-spacing: -1px;
}

.contact-heading {
  color: #92c6ad;
  font-size: 32px;
  line-height: 70px;
  word-spacing: -2px;
  letter-spacing: -3px;
  width: fit-content;
  margin-left: auto;
  padding-right: 150px;
  position: relative;
}

.contact-heading h1 {
  font-weight: 500;
  margin: 0;
}
.contact-heading span {
  color: #82b29b;
  font-weight: 200;
  font-style: italic;
}

.email-btn-container {
  position: absolute;
  right: 150px;
  bottom: -200px;
  cursor: pointer;
}

.email-btn {
  position: absolute;
}

.email-overlay {
  opacity: 0;
}

.email-btn-container:hover .email-btn {
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.email-btn-container:hover .email-overlay {
  opacity: 1;
  transition: 0.5s ease-in-out;
}

.infinite-text-loop-container {
  width: 100%; /* Set the width of the container to fit the page */
  overflow: hidden; /* Hide the overflow so the text stays within the container */
  position: absolute;
  bottom: 0;
}

.infinite-text-loop {
  display: flex; /* Use flexbox to align text horizontally */
  white-space: nowrap; /* Prevent text from wrapping to next line */
  animation: loop 20s linear infinite; /* Set the animation properties */
}

.text {
  color: #1c1712ce;
  font-size: 62px;
  font-weight: 400;
  text-transform: uppercase;
}

@keyframes loop {
  0% {
    transform: translateX(100%); /* Start from the right edge */
  }
  100% {
    transform: translateX(
      -160%
    ); /* Move to the left edge to create the loop effect */
  }
}

.influenced-landing-container {
  margin: 0 30px;
  display: grid;
  grid-template-columns: 80% 20%;
}

.influenced-banner-img-container {
  overflow: hidden;
}

.influenced-cover-image {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center right;
  min-height: 60vh;
}

.influenced-banner-txt {
  position: relative;
  grid-column: 11/13;
}

.influenced-banner-txt p {
  position: absolute;
  font-size: 18px;
  margin: 0;
  bottom: 0;
}

.influenced-heading {
  color: #4a86d9;
  margin: 15px 30px 120px 30px;
}

.influenced-heading h1 {
  font-size: 92px;
  font-weight: 600;
  letter-spacing: -4px;
  margin: 0;
}

.influenced-heading p {
  font-size: 64px;
  letter-spacing: -4px;
  line-height: 65px;
  margin: 0;
}

.influenced-overview {
  margin: 0 30px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  padding: 50px 0 80px 0;
}

.influenced-left-grid {
  grid-column: 2/5;
  color: #231f20;
}

.influenced-left-grid h6 {
  font-size: 24px;
  font-weight: 300;
  margin: 0 0 25px 0;
}
.influenced-left-grid p {
  font-size: 28px;
  letter-spacing: -1px;
  line-height: 24x;
  margin: 10px 0 50px 0;
}

.img-phone-1 {
  width: 100%;
}

.influenced-right-grid {
  grid-column: 6/12;
}

.influenced-right-grid h6 {
  font-size: 24px;
  font-weight: 300;
  margin: 0 0 25px 0;
}
.influenced-right-grid h3 {
  margin: 65px 0 45px 0;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -1px;
}

.influenced-right-grid h4 {
  font-size: 20px;
  font-weight: 300;
  margin: 20px 0 6px 0;
}

.influenced-right-grid p {
  font-weight: 300;
  line-height: 24px;
  margin: 0 0 10px 0;
}

.influenced-design-section {
  background-color: #e0dbd6;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  padding: 50px 0;
}

.influenced-design-section p {
  font-weight: 300;
  line-height: 24px;
  margin: 0 0 10px 0;
}
.influenced-design-section h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 15px 0;
}

.design-deliverables-left {
  grid-column: 2/5;
  color: #231f20;
}

.design-deliverables-left h3 {
  margin: 65px 0 45px 0;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -1px;
}

.design-deliverables-left ul {
  padding: 0;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
}

.design-deliverables-right {
  grid-column: 6/12;
}

.design-deliverables-right h3 {
  margin: 65px 0 45px 0;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -1px;
}

.influence-sitemap-container {
  width: 90%;
  margin: 0 auto;
}

.influence-sitemap-container h1 {
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -1px;
  margin: 50px 0 10px 0;
}

.sitemap-img-container {
  width: 85%;
  margin: 0 auto;
}

.sitemap {
  width: 100%;
}

.wireframe-slide-container {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.wireframe-slide-container h1 {
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -1px;
  margin: 50px 0 10px 0;
}

.wireframe-img-container {
  width: 100%;
  margin: 0 auto;
}

.wireframe-slider-img {
  width: 100%;
}

.wireframe-slider {
  position: relative;
  overflow: hidden;
}
.slide {
  position: absolute;
  width: 100%;
}

.wireframe-next-btn {
  position: absolute;
  bottom: 60px;
  right: 100px;
  text-decoration: underline;
  cursor: pointer;
  color: #231f20bb;
}

.wireframe-prev-btn {
  position: absolute;
  bottom: 60px;
  left: 100px;
  text-decoration: underline;
  cursor: pointer;
  color: #231f20bb;
}

.influenced-logo-section {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.influenced-logo-section h1 {
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -1px;
  margin: 50px 0 10px 0;
}

.influenced-logo-text-div {
  width: 50%;
}

.influenced-logo-p1 {
  font-size: 22px;
}
.influenced-logo-p2 {
  font-size: 16px;
  font-weight: 300;
}

.influenced-logo-img {
  padding: 50px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.logo-img-1 {
  width: 100%;
}
.logo-img-2 {
  width: 40%;
}

.color-palette {
  width: 70%;
}

.influenced-typography-section {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  padding: 50px 0;
}

.typography-left {
  grid-column: 2/6;
}

.influenced-typography-section p {
  margin: 0;
}

.influenced-welcome-screen-desktop {
  width: 80%;
  margin: 180px 0 0 35px;
}

.influenced-welcome-screen-mobile {
  width: 80%;
  display: block;
  margin: 0 auto;
  display: none;
}

.influenced-typography-img {
  grid-column: 7/13;
  margin-top: 80px;
  width: 100%;
}

.influenced-design-screens {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  padding: 150px 0 50px 0;
  position: relative;
}

.design-background-div {
  position: absolute;
  width: 100%;
  height: 1000px;
  top: 1600px;
  background-color: #e0dbd6;
  z-index: -1;
}

.design-screen-left {
  grid-column: 2/5;
}
.design-screen-left h1 {
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -1px;
}
.design-screen-left h3 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -1px;
}

.design-screen-left p {
  font-weight: 300;
}

.design-screen-right {
  grid-column: 6/11;
}

.design-screen-right img {
  width: 100%;
}

.home-screen-left {
  grid-column: 3/6;
}
.home-screen-left h3 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -1px;
  margin-top: 100px;
}
.home-screen-left p {
  font-weight: 300;
  margin-bottom: 50px;
}
.home-screen-left img {
  width: 100%;
}

.home-screen-right {
  grid-column: 7/10;
}
.influenced-spacer {
  height: 400px;
}
.home-screen-right h3 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -1px;
  margin-top: 60px;
}
.home-screen-right p {
  font-weight: 300;
  margin-bottom: 20px;
}
.home-screen-right img {
  width: 100%;
  padding: 40px 0;
}

.home-screen-left-mobile {
  display: none;
}

.profile-screen-left {
  padding: 80px 0;
}
.profile-screen-left img {
  width: 100%;
}

.p-heading-alternative {
  margin-top: 0 !important;
}

.landing-screens {
  margin: 0 60px;
  grid-column: 1/13;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  position: relative;
}

.landing-screens h3 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -1px;
  margin-top: 10px;
}

.landing-screens p {
  font-weight: 300;
}

.landing-page-text1 {
  grid-column: 1/5;
}
.landing-page-text2 {
  grid-column: 7/12;
}

.landing-screens-img {
  grid-column: 1/13;
  width: 100%;
}

.landing-background-div {
  position: absolute;
  width: 100%;
  height: 1350px;
  bottom: 0;
  background-color: #e0dbd6;
  z-index: -1;
}

.browsing-screens {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  padding: 150px 0 150px 0;
  position: relative;
}

.browsing-screens img {
  grid-column: 2/8;
  width: 100%;
}

.browsing-text {
  grid-column: 9/12;
  margin-top: 25%;
}

.browsing-text h3 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -1px;
}
.browsing-text p {
  font-weight: 300;
}

.prototype-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  padding: 35px 50px 0 50px;
  position: relative;
  background-color: #efe9e2;
}

.prototype-container h1 {
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -1px;
  grid-column: 1/4;
  margin: 0;
}

.prototype-text {
  font-weight: 300;
  grid-column: 2/6;
  margin-top: 15%;
}

.prototype-text p {
  width: 70%;
}

.prototype-text img {
  width: 100%;
}

.prototype-container video {
  grid-column: 8/11;
  width: 90%;
}

.thank-you {
  position: relative;
}

.thank-you p {
  padding: 80px;
  font-size: 58px;
  line-height: 65px;
  color: #292e32bc;
  letter-spacing: -1px;
}

.back-home {
  width: fit-content;
  position: absolute;
  bottom: 0;
  right: 80px;
  align-items: center;
  display: flex;
}
.back-home h3 {
  font-size: 24px;
  font-weight: 500;
  color: #4a86d9;
  margin: 0;
}

.back-home img {
  width: 50px;
  padding-left: 15px;
}

.thanks-heading {
  font-size: 84px;
  font-weight: 600;
  letter-spacing: -2px;
}

.thank-you-contact a {
  text-decoration: underline;
  color: #292e32bc !important;
}
.thank-you2 {
  position: relative;
}

.thank-you2 p {
  padding: 80px;
  font-size: 58px;
  line-height: 65px;
  color: #292e32bc;
  letter-spacing: -1px;
}

.back-home2 {
  width: fit-content;
  position: absolute;
  bottom: 0;
  right: 80px;
  align-items: center;
  display: flex;
}
.back-home2 h3 {
  font-size: 24px;
  font-weight: 500;
  color: #fe6902;
  margin: 0;
}

.thanks-heading2 {
  font-size: 84px;
  font-weight: 600;
  letter-spacing: -2px;
  color: #fe6902;
}

.thank-you-contact a {
  text-decoration: underline;
  color: #292e32bc !important;
}

.dev-portfolio-landing {
  display: grid;
  grid-template-columns: 80% 20%;
  margin: 0 30px;
}

.dev-portfolio-img-container {
  overflow: hidden;
}

.dev-portfolio-banner {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center right;
  min-height: 50vh;
}

.dev-portfolio-landing-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 16px;
}

.dev-portfolio-heading h1 {
  font-size: 92px;
  font-weight: 600;
  letter-spacing: -4px;
  line-height: 70px;
  margin: 30px;
  color: #fe6902;
}
.dev-portfolio-heading span {
  font-size: 64px;
  font-weight: 400;
  letter-spacing: -4px;
}

.dev-portfolio-heading-text {
  display: grid;
  grid-template-columns: 30% 70%;
  margin: 0 30px 100px 30px;
  gap: 30px;
}

.dev-portfolio-heading-text h4 {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: -1px;
  margin: 0 0 20px 0;
}
.dev-portfolio-heading-text p {
  font-size: 28px;
  letter-spacing: -1px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
}

.dev-portfolio-heading-text2 {
  width: 65%;
}
.dev-portfolio-heading-text2 p {
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 300;
  line-height: 20px;
}

.dark-background {
  background-color: #232323;
}

.dev-portfolio-process {
  width: 100%;
  padding: 100px;
}
.dev-portfolio-process h1 {
  font-size: 36px;
  font-weight: 300;
  letter-spacing: -1px;
  color: #f8f3ed;
  margin-bottom: 50px;
}

.dev-portfolio-process-text1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.dev-portfolio-process-text2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.dev-portfolio-process-text1 h3 {
  font-size: 32px;
  font-weight: 300;
  letter-spacing: -1px;
  margin: 0 0 20px 0;
  color: #f8f3ed;
}

.dev-portfolio-process-text1 p {
  width: 70%;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  line-height: 25px;
  margin: 0 0 20px 0;
  color: #f8f3ed;
}
.dev-portfolio-process-text2 h3 {
  font-size: 32px;
  font-weight: 300;
  letter-spacing: -1px;
  margin: 0 0 20px 0;
  color: #f8f3ed;
}

.dev-portfolio-process-text2 p {
  width: 70%;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  line-height: 25px;
  margin: 0 0 20px 0;
  color: #f8f3ed;
}

.dev-design-guide {
  margin: 30px 100px;
}

.dev-design-guide h1 {
  font-size: 36px;
  font-weight: 300;
  letter-spacing: -1px;
  color: #f8f3ed;
  margin-bottom: 50px;
}

.design-guide-img-container {
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 60px;
  width: 100%;
  padding-bottom: 100px;
}

.design-guide-img-container img {
  width: 100%;
}

.dev-responsive-design-img-container {
  margin: 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.dev-responsive-design-img-container img {
  width: 100%;
  align-self: end;
}

.dev-responsive-design-text {
  margin: 5% 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.dev-responsive-design-text h3 {
  font-size: 32px;
  font-weight: 300;
  letter-spacing: -1px;
  margin: 0 0 20px 0;
}
.dev-responsive-design-text p {
  font-weight: 300;
  margin: 0 0 20px 0;
}
.dev-responsive-design-text h4 {
  font-weight: 300;
  margin-top: 50px;
  text-decoration: underline;
  color: #fe6902;
}

.dev-about-img {
  background-color: #232323;
  padding: 60px;
}

.dev-about-img img {
  width: 100%;
}

.dev-recent-work {
  padding: 5% 10%;
}

.dev-recent-work h1 {
  font-size: 32px;
  font-weight: 300;
  letter-spacing: -1px;
  margin: 0 0 60px 0;
}
.dev-recent-work img {
  width: 100%;
}

.minimalist-landing {
  display: grid;
  grid-template-columns: 80% 20%;
}

.minimalist-img-container {
  overflow: hidden;
}

.minimalist-banner {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center right;
  min-height: 50vh;
}

.minimalist-landing-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.minimalist-heading {
  width: 60%;
  margin-left: 80px;
}

.minimalist-heading h1 {
  font-size: 92px;
  font-weight: 600;
  letter-spacing: -4px;
  line-height: 60px;
  color: #1f2223;
}
.minimalist-heading span {
  font-size: 64px;
  font-weight: 400;
  letter-spacing: -4px;
  line-height: 40px;
}

.minimalist-overview {
  padding: 0 80px;
  width: 85%;
}

.minimalist-overview h3 {
  font-size: 28px;
  font-weight: 200;
}

.minimalist-overview p {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 15px;
}

.minimalist-home-screen {
  padding: 80px 0;
}

.minimalist-home-screen hr {
  border: none;
  height: 1px;
  background-color: #878686;
}
.minimalist-home-screen h2 {
  padding-left: 40px;
  font-size: 32px;
  font-weight: 300;
}

.home-screen-content {
  width: 80%;
  margin: 60px auto;
}

.home-screen-content p {
  font-size: 20px;
  font-weight: 300;
  width: 60%;
}

.home-screen-content img {
  width: 90%;
}

.minimalist-home-desktop {
  width: 100%;
}

.minimalist-home-desktop hr {
  border: none;
  height: 1px;
  background-color: #878686;
  margin-bottom: 30px;
}
.minimalist-home-desktop h2 {
  padding: 80px 0 0 40px;
  margin: 0 0 20px 0;
  font-size: 32px;
  font-weight: 300;
}
.minimalist-home-desktop h3 {
  margin: 0 0 20px 0;
  font-size: 24px;
  font-weight: 300;
}

.minimalist-home-desktop p {
  font-size: 20px;
  font-weight: 300;
  width: 60%;
}

.mobile-home-screen-p {
  width: 80%;
  margin: 60px auto 0 auto;
}

.minimalist-home-desktop img {
  display: block;
  margin: 0 auto;
  padding: 30px 16px 0 16px;
  max-width: 100%;
}

.minimalist-container {
  background-color: #fff7f1;
}

.minimalist-container-2 {
  background-color: #d9d9d9b3;
}

.minimalist-cart-screen {
  width: 100%;
  padding: 40px 0;
}

.minimalist-footer {
  background-color: #192825;
}

.thank-you3 {
  position: relative;
  padding: 80px 0;
}

.thank-you3 p {
  font-size: 58px;
  line-height: 65px;
  padding-left: 80px;
  color: #f8f3ed;
  letter-spacing: -1px;
  margin: 0;
}

.back-home3 {
  width: fit-content;
  position: absolute;
  bottom: 30px;
  right: 80px;
  align-items: center;
  display: flex;
}
.back-home3 h3 {
  font-size: 24px;
  font-weight: 500;
  color: #f8f3ed;
  margin: 0;
  text-decoration: underline;
}

.thanks-heading3 {
  font-size: 84px;
  font-weight: 600;
  letter-spacing: -2px;
  color: #f8f3ed;
}

.thank-you-contact a {
  text-decoration: underline;
  color: #afafabbc !important;
}

.outerContainer {
  width: 100%;
}

.footer-section {
  display: grid;
  grid-template-columns: 30% 35% 35%;
  font-size: 16px;
  color: #1c1712;
}

.footer-section-mobile {
  display: none;
}

.footer-section h3 {
  font-weight: 600;
  font-size: 16px;
}
.footer-section-mobile h3 {
  font-weight: 600;
  font-size: 16px;
}

.footer-section ul {
  margin: 0;
  padding: 0 0 20px 0;
  line-height: 24px;
}
.footer-section-mobile ul {
  margin: 0;
  padding: 0 0 20px 0;
  line-height: 24px;
}

.footer-section a {
  color: #1c1712;
}
.footer-section-mobile a {
  color: #1c1712;
}

.left-footer-section {
  border-right: 1px solid #1c1712;
  padding: 24px;
}

.footer-cv {
  cursor: pointer;
  text-decoration: underline;
  overflow: hidden;
}

.footnote {
  font-size: 14px;
  color: #192825;
}

.ruan-link {
  transition: font-weight 0.5s ease, color 0.5s ease;
  font-weight: normal;
  color: #192825;
}

.ruan-link:hover {
  font-weight: bold;
  color: #92c6ad;
}

.center-footer-section {
  padding: 24px;
}

.form-container {
  position: relative;
  width: 82%;
  margin: 25px 0;
  height: 60px;
}

.right-footer-section {
  padding: 24px;
}

.right-footer-section input {
  border: none;
  border-bottom: 1px solid #1c1712;
  width: 100%;
  background-color: #f8f3ed;
  font-family: "Switzer", sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.right-footer-section input:focus {
  outline: none;
}

.right-footer-section button {
  cursor: pointer;
  border: none;
  background-color: #f8f3ed;
  font-family: "Switzer", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  position: absolute;
  right: 0;
  bottom: 0;
}

.right-footer-section button:hover {
  color: #53866e;
}

.sent-message {
  color: #53866e;
}

.mobile-footer-nav {
  display: grid;
  grid-template-columns: 50% 50%;
}

@media (max-width: 1480px) {
  .profile-photo-div {
    right: 140px;
  }
}

@media (max-width: 1200px) {
  .landing-container {
    grid-template-columns: 1fr;
  }
  .content-div {
    min-height: 110vh;
  }

  .image-container {
    min-height: 80vh;
    max-height: 80vh;
  }

  .slide2-container {
    grid-template-columns: 25% 75%;
  }
  .profile-photo-div {
    right: 300px;
  }
}

@media (max-width: 990px) {
  .about-text1 {
    font-size: 28px;
  }
}

@media (max-width: 880px) {
  @keyframes loop {
    0% {
      transform: translateX(100%); /* Start from the right edge */
    }
    100% {
      transform: translateX(
        -320%
      ); /* Move to the left edge to create the loop effect */
    }
  }

  .text {
    font-size: 54px;
  }

  .mobile-scroll-btn {
    display: block;
    width: 150px;
    margin: 0 auto;
  }

  .desktop-scroll-btn {
    display: none;
  }

  .text-overlay {
    width: 85%;
  }

  .text-overlay p {
    font-size: 86px;
    line-height: 65px;
  }

  .portfolio-cards-container {
    display: block;
  }

  .slide1-container {
    display: none;
  }
  .slide2-container {
    display: none;
  }

  .about-mobile-slider {
    display: block;
    position: relative;
    margin-bottom: 80px;
  }

  .about-title {
    top: 50px;
    left: 16px;
    font-size: 72px;
    margin: 0;
    line-height: 65px;
    width: fit-content;
  }

  .about-title2 {
    top: -0;
    left: 16px;
    font-size: 92px;
    line-height: 65px;
    margin: 0;
  }

  .about-text1 {
    padding: 140px 16px 0px 16px;
    font-size: 28px;
    line-height: 35px;
  }

  .about-image-div {
    display: flex;
    justify-content: right;
  }

  .about-text2 {
    width: 100%;
    margin: 25px 16px;
  }

  .about-mobile-slider2 {
    display: block;
    background-color: #1e1e1e;
    position: relative;
    padding: 60px 0 160px 0;
  }

  .about-heading {
    top: 45px;
    left: 16px;
  }

  .about-heading p {
    margin: 0;
    width: 100%;
  }

  .profile-photo-div {
    right: 140px;
  }

  .about-img-2 {
    width: 280px;
    position: relative;
    display: block;
    margin: 15px auto 0 auto;
    bottom: 0;
  }
  .img-container-slide2 {
    height: auto;
  }

  .next-btn {
    bottom: -80px;
    right: 15px;
    left: auto;
    cursor: pointer;
  }

  .overlay-next-btn {
    bottom: -80px;
    right: 15px;
    left: auto;
    cursor: pointer;
    opacity: 0;
  }

  .prev-btn {
    right: 40px;
  }

  .overlay-prev-btn {
    right: 40px;
  }

  .text-container-slide2 {
    grid-template-columns: 1fr;
    gap: 0;
    padding: 35px 0;
  }

  .about-footer-section h2 {
    display: none;
  }

  .about-footer-mobile {
    display: block !important;
    font-size: 92px !important;
    line-height: 70px !important;
  }

  .about-footer-section p {
    width: 70%;
    padding-top: 270px;
  }

  .back-home-btn {
    right: 0;
  }

  .influenced {
    max-width: 300px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .influenced-heading {
    margin: 16px;
  }

  .influenced-landing-container {
    grid-template-columns: 1fr;
    margin: 0;
  }
  .influenced-heading h1 {
    font-size: 64px;
    line-height: 60px;
  }
  .influenced-heading p {
    font-size: 42px;
    line-height: 40px;
    letter-spacing: -2px;
    padding-top: 20px;
  }

  .influenced-overview {
    grid-template-columns: 1fr;
  }

  .influenced-left-grid {
    grid-column: 1 / -1;
  }
  .influenced-right-grid {
    grid-column: 1 / -1;
  }

  .influenced-right-grid h3 {
    font-size: 28px;
  }

  .influenced-design-section {
    grid-template-columns: 1fr;
    padding: 16px;
  }

  .design-deliverables-left h3 {
    font-size: 26px;
  }

  .design-deliverables-left {
    grid-column: 1 / -1;
  }
  .design-deliverables-right {
    grid-column: 1 / -1;
  }

  .design-deliverables-right h3 {
    margin: 30px 0;
    font-size: 26px;
  }

  .influence-sitemap-container h1 {
    font-size: 26px;
  }
  .wireframe-slide-container h1 {
    font-size: 26px;
  }

  .wireframe-next-btn {
    bottom: 0;
  }

  .wireframe-prev-btn {
    bottom: 0;
  }

  .influenced-logo-section h1 {
    font-size: 26px;
  }

  .influenced-logo-p1 {
    font-size: 18px;
  }

  .influenced-logo-text-div {
    width: 100%;
  }

  .influenced-logo-section {
    margin: 0 16px;
  }

  .influenced-logo-img {
    padding: 25px 0;
  }

  .influenced-typography-section {
    grid-template-columns: 1fr;
  }

  .typography-left {
    grid-column: 1 / -1;
  }
  .influenced-typography-img {
    grid-column: 1 / -1;
    margin-top: 30px;
  }

  .influenced-typography-section p {
    margin: 16px;
  }

  .influenced-welcome-screen-desktop {
    display: none;
  }

  .influenced-welcome-screen-mobile {
    display: block;
  }

  .color-palette {
    width: 100%;
  }

  .design-screen-left {
    grid-column: 1 / -1;
    margin: 16px;
  }

  .design-screen-right img {
    width: 80%;
    display: block;
    margin: 0 auto;
  }

  .influenced-design-screens {
    padding: 50px 0;
  }

  .design-screen-right {
    grid-column: 1 / -1;
  }
  .home-screen-left {
    display: none;
  }
  .home-screen-right {
    display: none;
  }

  .home-screen-left-mobile {
    display: block;
    grid-column: 1 / -1;
  }

  .home-screen-left-mobile h3 {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -1px;
    margin: 16px;
  }
  .home-screen-left-mobile p {
    font-weight: 300;
    margin: 16px;
  }
  .home-screen-left-mobile img {
    width: 100%;
  }

  .brandProfileMobile {
    width: 80% !important;
    display: block;
    margin: 40px auto;
  }

  .design-background-div {
    display: none;
  }

  .landing-screens {
    grid-template-columns: 1fr;
    margin: 16px;
  }

  .landing-page-text1 {
    grid-column: 1 / -1;
  }

  .landing-screens-img {
    grid-column: 1 / -1;
  }

  .landing-page-text2 {
    grid-column: 1 / -1;
  }

  .browsing-screens {
    grid-template-columns: 1fr;
    padding: 50px 0;
  }
  .browsing-screens img {
    grid-column: 1 / -1;
    padding: 0 10%;
  }
  .browsing-text {
    grid-column: 1 / -1;
    width: 70%;
    margin-left: 30px;
    margin-top: 0;
  }
  .prototype-container {
    grid-template-columns: 1fr;
  }
  .prototype-text {
    grid-column: 1 / -1;
    margin-top: 5%;
  }
  .prototype-text p {
    width: 80%;
    margin: 0;
  }

  .prototype-text img {
    width: 80%;
    margin-top: 20px;
  }
  .prototype-container video {
    grid-column: 1 / -1;
    width: 80%;
  }

  .thank-you p {
    padding: 0 16px 80px 16px;
    font-size: 32px;
    line-height: 35px;
  }

  .thanks-heading {
    font-size: 42px;
  }

  .dev-portfolio {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .dev-portfolio-landing {
    grid-template-columns: 1fr;
    margin: 0 16px;
  }

  .dev-portfolio-landing-text ul {
    padding: 0;
  }

  .dev-portfolio-heading h1 {
    font-size: 64px;
    line-height: 60px;
    margin: 16px;
  }
  .dev-portfolio-heading span {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 10px !important;
  }

  .minimalist-heading {
    margin: 16px;
    width: 90%;
  }
  .minimalist-landing {
    grid-template-columns: 1fr;
  }

  .minimalist-landing-text ul {
    padding-left: 16px;
  }

  .minimalist-heading h1 {
    font-size: 58px;
    line-height: 40px;
  }
  .minimalist-heading span {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 10px !important;
  }

  .dev-portfolio-process {
    padding: 40px;
  }

  .dev-portfolio-process-text1 {
    grid-template-columns: 1fr;
  }
  .dev-portfolio-process-text2 {
    grid-template-columns: 1fr;
  }

  .dev-portfolio-process-text1 p {
    width: 100%;
  }
  .dev-portfolio-process-text2 p {
    width: 100%;
  }

  .dev-portfolio-heading-text {
    grid-template-columns: 1fr;
    margin: 16px;
  }

  .dev-portfolio-heading-text2 {
    width: 100%;
  }

  .design-guide-img-container {
    grid-template-columns: 1fr;
  }

  .dev-design-guide {
    margin: 16px;
  }

  .design-guide-img-container img {
    width: 80%;
  }

  .dev-responsive-design-img-container {
    grid-template-columns: 1fr;
  }

  .dev-responsive-design-text {
    grid-template-columns: 1fr;
    margin: 16px;
    gap: 10px;
  }

  .dev-recent-work {
    padding: 50px 16px;
  }

  .thank-you2 p {
    padding: 0 16px 80px 16px;
    font-size: 32px;
    line-height: 35px;
  }

  .thanks-heading2 {
    font-size: 42px;
  }

  .minimalist-overview {
    width: 100%;
    padding: 16px;
  }

  .home-screen-content {
    margin: 16px;
  }

  .home-screen-content p {
    width: 100%;
    font-size: 16px;
  }

  .minimalist-home-desktop img {
    padding: 50px 16px;
  }

  .minimalist-home-screen {
    padding: 20px 0;
  }

  .minimalist-home-screen h2 {
    padding-left: 16px;
  }
  .minimalist-home-desktop h2 {
    padding-left: 16px;
  }

  .mobile-home-screen-p {
    margin: 16px;
  }

  .minimalist-home-desktop p {
    width: 100%;
  }

  .thank-you3 p {
    padding: 0 16px 80px 16px;
    font-size: 32px;
    line-height: 35px;
  }

  .thanks-heading3 {
    font-size: 42px;
  }

  .landing-background-div {
    height: 1000px;
  }

  .welcome-text-container {
    display: none;
  }
  .welcome-mobile-text-container {
    display: block;
    line-height: 55px;
  }
  .splash-btn-container {
    bottom: 25%;
    right: 200px;
    cursor: pointer;
  }

  .text-block1 {
    display: block;
  }
}

@media (max-width: 680px) {
  .contact-container {
    height: 80vh;
  }
  .contact-heading {
    width: 70%;
    font-size: 28px;
    line-height: 50px;
    margin-left: 16px;
    padding: 35px 0 0 0;
  }

  .contact-heading h1 {
    font-weight: 500;
    margin: 0;
  }
  .contact-heading span {
    font-size: 48px;
    font-weight: 200;
    font-style: italic;
  }
  .footer-section {
    display: none;
  }
  .footer-section-mobile {
    display: block;
  }
  .left-footer-section {
    border: none;
  }
  .right-footer-section {
    padding-top: 60px;
  }
}

@media (max-width: 575px) {
  @keyframes loop {
    0% {
      transform: translateX(100%); /* Start from the right edge */
    }
    100% {
      transform: translateX(
        -350%
      ); /* Move to the left edge to create the loop effect */
    }
  }

  .text {
    font-size: 42px;
  }

  .text2 {
    margin-bottom: 80px;
  }

  .image-container {
    min-height: 60vh;
    max-height: 60vh;
  }

  .about-image {
    width: 200px;
  }

  .portfolio-heading {
    line-height: 50px;
    text-align: left;
    margin-left: 16px;
    padding-top: 40px;
  }

  .answer2 {
    padding-left: 30%;
  }

  .answer3 {
    padding-left: 30%;
  }

  .content-div {
    min-height: 100vh;
    padding: 0;
    margin: 16px 16px 80px 16px;
  }
  .profile-photo-div {
    left: 0;
  }

  .view-portfolio {
    right: 0;
    bottom: -100px;
  }

  .about-footer-section {
    margin: 60px 16px;
  }

  .about-footer-section p {
    width: 80%;
  }
  .navContainer {
    height: auto;
  }
}

@media (max-width: 481px) {
  @keyframes loop {
    0% {
      transform: translateX(100%); /* Start from the right edge */
    }
    100% {
      transform: translateX(
        -430%
      ); /* Move to the left edge to create the loop effect */
    }
  }
  .text-overlay p {
    font-size: 72px;
    line-height: 65px;
  }

  .landing-page-heading {
    line-height: 40px;
    margin-top: 20%;
  }
  .landing-page-heading span {
    font-size: 48px;
  }

  .profile-photo-div {
    width: 100%;
    top: 180px;
  }
  .design-guide-img-container img {
    width: 100%;
  }

  .main-logo {
    width: 80%;
  }

  .navContainer {
    padding: 16px;
  }

  .content-div {
    height: 80vh;
  }

  .email-btn-container {
    left: 20px;
  }
}

@media (max-width: 400px) {
  .text {
    font-size: 32px;
  }

  .content-div {
    min-height: 90vh;
  }

  @keyframes loop {
    0% {
      transform: translateX(100%); /* Start from the right edge */
    }
    100% {
      transform: translateX(
        -410%
      ); /* Move to the left edge to create the loop effect */
    }
  }
}
@media (max-width: 1650px) {
  .profile-photo-div {
    right: 200px;
  }
}
@media (max-width: 1550px) {
  .profile-photo-div {
    right: 160px;
  }
}
